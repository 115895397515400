<template>
  <div class="bg-hp-gray" style="min-height: 100vh;">
    <header class="w-full">
      <Menu class="bg-hp-gray" />
    </header>


    <!-- Main content -->
    <div class="pt-36 pb-80 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4" style="min-height: 100vh;">
      <div class="text-center text-blue-900 font-bold text-4xl mb-16">
        Case Studies
      </div>

      <div class="grid sm:grid-cols-3 gap-y-10">
        <CaseStudy 
          :before="require('@/assets/case-study/jane-before.jpg')"
          :after="require('@/assets/case-study/jane-after.jpg')"
          text="Homeowner filed a roof insurance claim, but didn't understand the claims process."
          slug="jane"
        />

        <CaseStudy 
          :before="require('@/assets/case-study/alex-before.jpg')"
          :after="require('@/assets/case-study/alex-after.jpg')"
          text="First time homebuyer needed new roof, gutters, soffit, fascia and mold removal."
          slug="alex"
        />

        <CaseStudy 
          :before="require('@/assets/case-study/sikina 1.jpg')"
          :after="require('@/assets/case-study/sikina 2.jpg')"
          text="A fire destroyed part of this homeowners rear deck, due to age."
          slug="sikina"
        />

        <CaseStudy 
          :before="require('@/assets/case-study/cecilia.png')"
          :after="require('@/assets/case-study/cecilia.png')"
          text="Homeowner needed home insurance after 7 years of no coverage and a greenhouse teardown."
          slug="cecilia"
        />
      </div>
    </div>


    
    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/menu";
import Footer from "@/components/large-footer.vue";
import CaseStudy from "@/components/case-study.vue";

export default {
  components: {
    Menu,
    Footer,
    CaseStudy
  },
  methods: {
    goTo(link) {
      window.scrollTo(0, 0);
      this.$router.push(link);
    }
  }
}
</script>


<style scoped>
header {
  widows: 100%;
  position: fixed;
  z-index: 1000;
}
</style>